/**
 * Only use ES5 in this file since it's also read in gatsby-node.js,
 * which is run by node.js, which currently does not support ES6...
 **/

/**
 * Documents query is an array with these types of objects:
 * [Object: null prototype] {
 * breadcrumb: [Array],
 * childMarkdownRemark: [Object: null prototype],
 * name: '28 Bygga staket med barn',
 * slug: '/2021/02/28-bygga-staket-med-barn',
 * template: null
 * }
 */

const parseDocumentDate = (documentQuery) => {
  const yearAndMonthSlug = documentQuery.breadcrumb[1].slug
  const year = yearAndMonthSlug.slice(1, 5)
  const month = yearAndMonthSlug.slice(6, 8)
  const day = documentQuery.name.slice(0, 2)

  return new Date(year, month, day)
}

const documentsToPosts = (documentsQuery) => {
  const posts = documentsQuery.map((documentQuery) => ({
    title: documentQuery.name.slice(3),
    html: documentQuery.childMarkdownRemark.html,
    slug: documentQuery.slug,
    date: parseDocumentDate(documentQuery),
    template: documentQuery.template,
  }))
  return posts
}

module.exports.documentsToPosts = documentsToPosts

import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'

const { documentsToPosts } = require('utils/massage-documents-query')

export default ({ data }) => {
  const posts = documentsToPosts(data.allGoogleDocs.nodes)

  const displayDate = (date) => {
    const year = date.getFullYear()

    let month = date.getMonth()
    if (month < 10) {
      month = `0${month}`
    }

    let day = date.getDate()
    if (day < 10) {
      day = `0${day}`
    }

    return `${year}-${month}-${day}`
  }

  return (
    <Layout pageTitle='Home'>
      <oma-grid-row>
        <section className='section'>
          <h1 className='page__heading'>
            En blogg om det som händer i vårt liv på landet. Allt i en enda
            salig röra!
          </h1>
        </section>
      </oma-grid-row>
      <oma-grid-row>
        <section className='section'>
          {posts.map((post) => (
            <article key={post.slug} className='blog-post'>
              <h2 className='blog-post__title'>{post.title}</h2>
              <p className='blog-post__date'>{displayDate(post.date)}</p>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <hr className='blog-post__separator' />
            </article>
          ))}
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  query {
    allGoogleDocs(sort: { fields: slug, order: DESC }) {
      nodes {
        breadcrumb {
          slug
        }
        childMarkdownRemark {
          html
        }
        name
        slug
        template
      }
    }
  }
`
